"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserStatus = void 0;
var UserStatus;
(function (UserStatus) {
  UserStatus["ACTIVE"] = "ACTIVE";
  UserStatus["INACTIVE"] = "INACTIVE";
})(UserStatus || (exports.UserStatus = UserStatus = {}));
