"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateUserType = void 0;
var CreateUserType;
(function (CreateUserType) {
  CreateUserType["ENTERPRISE_USER"] = "ENTERPRISE_USER";
  CreateUserType["PARTNER_USER"] = "PARTNER_USER";
  CreateUserType["PORTFOLIO_USER"] = "PORTFOLIO_USER";
  CreateUserType["AUDITOR_USER"] = "AUDITOR_USER";
  CreateUserType["CERTIFIER_USER"] = "CERTIFIER_USER";
})(CreateUserType || (exports.CreateUserType = CreateUserType = {}));
