import { Pipe, PipeTransform } from '@angular/core';

type NavigateFunc = (...args: never[]) => string[];

@Pipe({
  name: 'navigate',
  standalone: true,
})
export class NavigatePipe implements PipeTransform {
  transform<F extends NavigateFunc>(
    routeFunction: F,
    ...args: Parameters<F>
  ): string {
    const url: string[] = routeFunction(...args);
    return '/' + url.join('/');
  }
}
