"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContractType = void 0;
var ContractType;
(function (ContractType) {
  ContractType["PERMANENT"] = "PERMANENT";
  ContractType["CONSULTANT"] = "CONSULTANT";
})(ContractType || (exports.ContractType = ContractType = {}));
