"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserScope = void 0;
var UserScope;
(function (UserScope) {
  UserScope["INTERNAL"] = "INTERNAL";
  UserScope["EXTERNAL"] = "EXTERNAL";
})(UserScope || (exports.UserScope = UserScope = {}));
