import { Injectable } from '@angular/core';
import {
  notificationRouteMapping,
  RoutesConfig,
  userTypeRouteMapping,
} from '@ieApp/routes.config';
import { UserType } from 'shared-general-libs/type/user';

type RoutesConfigType = typeof RoutesConfig;
type RouteFunction = RoutesConfigType[keyof RoutesConfigType];

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public to = RoutesConfig;

  constructor() {}

  static get(routeFunc: RouteFunction) {
    const urlArr = routeFunc();
    return urlArr[urlArr.length - 1];
  }

  getRouteByUserType(userType: UserType) {
    return '/' + userTypeRouteMapping[userType]().join('/');
  }

  getNotificationRouteByUserType(userType: UserType) {
    return notificationRouteMapping[userType]();
  }
}
