"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserType = void 0;
var UserType;
(function (UserType) {
  UserType["SUPER_ADMIN"] = "SUPER_ADMIN";
  UserType["ENTERPRISE_USER"] = "ENTERPRISE_USER";
  UserType["PARTNER_USER"] = "PARTNER_USER";
  UserType["PORTFOLIO_USER"] = "PORTFOLIO_USER";
  UserType["CERTIFIER_USER"] = "CERTIFIER_USER";
  UserType["AUDITOR_USER"] = "AUDITOR_USER";
  UserType["ENTERPRISE_ADMIN"] = "ENTERPRISE_ADMIN";
})(UserType || (exports.UserType = UserType = {}));
